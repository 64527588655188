import React from "react"

import useTranslations from "../components/useTranslations"
import SEO from "../components/seo"
import "./index.css"
import ContactForm from "../components/forms/contactForm"
import { FaEnvelope } from "react-icons/fa"
import MeImage from '../components/meImage';

const IndexPage = props => {
  const t = useTranslations()

  return (
    <article className="home">
      <SEO title="Home" lang={props.locale} />
      <section className="personalSection visibleContent">
        <MeImage  />
        <p>{t.HiMyNameIs}</p>
        <h2 className="fullNameHeader">Juan Carlos Espinoza</h2>
      </section>
      <section className="visibleContent">
        <p>
          {t.ImACitizenInHonduras} <span aria-label="Honduras flag" role="img">🇭🇳</span>
        </p>
      </section>
      <section className="visibleContent">
        <p>{t.IhaveAUDegree}</p>
      </section>
      <section className="visibleContent">
        <p>{t.IWriteSoftwareForFun}</p>
      </section>
      <section className="visibleContent">
        <p>{t.IlikeLangualesCultureDance}</p>
      </section>
      <section className="visibleContent">
        <p>
          {t.IWouldLoveHearingFromYou} ^_^
        </p>
      </section>
      <section className="visibleContent">
        <FaEnvelope className="sectionIcon" />
        <h3>{t.SendAMessage}</h3>
        <ContactForm translations={t} />
      </section>
    </article>
  )
}

export default IndexPage
